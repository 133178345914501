import React from "react";
import { Redirect, Route, Switch } from "react-router";
import paths from "../paths";
import MainPageView from "../Views/MainPageView";
import AboutView from "../Views/AboutView";
import PricesView from "../Views/PricesView";
import OnlineView from "../Views/OnlineView";
import ContactsView from "../Views/ContactsView";

function Routes() {
    return (
            <Switch>
                <Route exact path={paths.index} component={MainPageView}/>
                <Route exact path={paths.about} component={AboutView}/>
                <Route exact path={paths.prices} component={PricesView}/>
                <Route exact path={paths.online} component={OnlineView}/>
                <Route exact path={paths.contacts} component={ContactsView}/>
                <Redirect to={paths.index} />
            </Switch>
    )
}

export default Routes;