import React from "react";
import "./About.css"
import withInfoIcon from "../../components/HOCs/InfoIcon";
import AboutImg from "../../assets/image/about-img-2.jpg"

const FirstIcon = ({ownStyle}) => <i className="fas fa-award" style={ownStyle}></i>
const SecondIcon = ({ownStyle}) => <i className="fas fa-gavel" style={ownStyle}></i>
const ThirdIcon = ({ownStyle}) => <i className="fas fa-child" style={ownStyle}></i>

const FirstElement = withInfoIcon(FirstIcon, "СТАЖ", "БОЛЕЕ 20 ЛЕТ")
const SecondElement = withInfoIcon(SecondIcon, "ВЫИГРАННЫХ ДЕЛ", "БОЛЕЕ 300")
const ThirdElement = withInfoIcon(ThirdIcon, "ДОВОЛЬНЫХ КЛИЕНТОВ", "БОЛЕЕ 1000")

function AboutView() {
    return (
        <React.Fragment>
            <div className={"about-area"}>
                <div className={"about-container"}>
                    <div className={"about-row"}>
                        <FirstElement/>
                        <SecondElement/>
                        <ThirdElement/>
                    </div>
                </div>
            </div>
            <div className={"about-area-2"}>
                <div className={"about-container-2"}>
                    <div className={"about-row-2"}>
                        <div className={"about-img-cont"}>
                            <div className={"about-img-wrapper"}>
                                <img src={AboutImg} alt="" className={"about-img"}/>
                            </div>
                        </div>
                        <div className={"about-text-cont"}>
                            <div className={"about-text-wrapper"}>
                                <div className={"about-text-1"}>
                                    <h2 className={"about-text-1"}>
                                        ОБО МНЕ
                                    </h2>
                                    <p className={"about-text-2"}>Добрый день! Я, адвокат Аврамук  Анжела Валерьевна приветствую всех на своем сайте.
                                        Осуществляю адвокатскую деятельность с 1998 г. Регистрационный номер 30/6 в реестре адвокатов Астраханской области.
                                        Смысл моей работы – оказание квалифицированной юридической помощи.
                                        В настоящее время являюсь действующим адвокатом и исполняю обязанности заведующей филиала «Адвокатская консультация № 114» Межреспубликанской коллегии адвокатов (г. Москва).  Филиал расположен в ЗАТО  Знаменск Астраханской области.
                                        Предоставляю юридические услуги в таких областях как гражданское право, уголовное право, административное право, жилищное право, семейное право, трудовое право, наследственное право, сделки с  недвижимостью и землей, споры по договорам ОСАГО и КАСКО, защита прав потребителей, оспаривание действий  должностных  лиц, защита прав военнослужащих и военных пенсионеров, споры с РУЖО по обеспечению жильем военнослужащих и членов их семей,  споры с Пенсионным фондом РФ по вопросам досрочного назначения льготных пенсий.
                                        Юридические услуги оказываю   путем консультирования, в том числе он-лайн, составления исков, заявлений, жалоб, ходатайств и других документов правового характера, представления интересов доверителей в гражданском и административном судопроизводстве, в том числе арбитраже, участия в качестве представителя или защитника доверителя в уголовном судопроизводстве и производстве по делам об административных правонарушениях, представления интересов  доверителей в органах государственной власти, органах местного самоуправления, общественных объединениях и иных организациях, в исполнительном производстве, а также при исполнении уголовного наказания.
                                        Своевременное обращение к адвокату – это залог успешного разрешения  Вашего дела!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutView;