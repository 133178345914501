import React from "react";
import PropTypes from 'prop-types';
import "./ServiceIcon.css";

function withServiceIcon(WrappedIcon, bigText, smallText) {
    return () => {
        const style = {
            fontSize: "60px",
            lineHeight: "1.65",
            color: "#c0b596",
            transition: "all .3s",
            width: 100,
            height: "auto",
        }
        return (
            <div className="serviceIcon-el">
                <div className={"serviceIcon-wrap"}>
                    <div className={"serviceIcon-icon"}>
                        <WrappedIcon ownStyle={style}/>
                    </div>
                    <div className={"serviceIcon-text"}>
                        <h3 className={"big-text-service"}>{bigText}</h3>
                        {/*<p className={"small-text-service"}>{smallText}</p>*/}
                        <p className={"small-text-service"}>{smallText.map((text, index) => <li key={index} className={"small-text-li"}>{text}</li>)}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withServiceIcon;

withServiceIcon.propTypes = {
    smallText: PropTypes.arrayOf(PropTypes.string)
}