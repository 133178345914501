import React from "react";
import "./OnlineView.css"

function OnlineView() {
    return (
        <div className={"online-container"}>
            <h1 className={"online-title"}>Консультация On-Line</h1>
            <div className={"online-text"}>Если вам необходима консультация, но
                вы живете в другом городе или у вас нет возможности приехать, мы можем договориться о личной
                консультации по
                Skype, WhatsApp или другому, удобному для Вас, способу.
            </div>
            <h1 className={"online-title-2"}>Стоимсоть</h1>
            <div className={"online-text"}>Стоимость консультации - 1 000 рублей / час. Свяжитель любым удобным
                способом и мы договоримся о времени.
            </div>
        </div>
    )
}

export default OnlineView;