import React from "react";
import "./PriceView.css"
import withServiceIcon from "../../components/HOCs/ServiceIcon";
import AccordionView from "./AccordionView";
import ugol from "../../assets/image/ugol.webp"
import grazhd from "../../assets/image/grazhd.webp";
import voen from "../../assets/image/voen.webp";
import administrat from "../../assets/image/administrat.webp";
import administrat2 from "../../assets/image/administrat2.webp";
import arbitrazh from "../../assets/image/arbitrazh.webp";

const UgolIcon = ({ownStyle}) => <img src={ugol} alt={""} style={ownStyle}/>
const GrazhdIcon = ({ownStyle}) => <img src={grazhd} alt={""} style={ownStyle}/>
const VoenIcon = ({ownStyle}) => <img src={voen} alt={""} style={ownStyle}/>
const AdministratIcon = ({ownStyle}) => <img src={administrat} alt={""} style={ownStyle}/>
const Administrat2Icon = ({ownStyle}) => <img src={administrat2} alt={""} style={ownStyle}/>
const ArbitrazhIcon = ({ownStyle}) => <img src={arbitrazh} alt={""} style={ownStyle}/>

const FirstElement = withServiceIcon(UgolIcon, "УГОЛОВНЫЕ ДЕЛА",
    ["Преступления против личности", "Преступления против собственности", "Должностные преступления",
        "Преступления против военной службы", "Преступления против безопасности движения"])
const SecondElement = withServiceIcon(GrazhdIcon, "ГРАЖДАНСКИЕ ДЕЛА",
    ["Жилищные споры", "Семейные споры", "Трудовые споры", "Споры, связанные с наследованием имущества", "Споры по ОСАГО и КАСКО",
        "Споры по защите прав потребителей;"])
const ThirdElement = withServiceIcon(VoenIcon, "Дела по защите прав военнослужащих и членов их семей",
    ["Споры с РУЖО (жилищная субсидия, ипотека)",
        "Оспаривание действий должностных лиц",
        "Привлечение к дисциплинарной и материальной ответственности"])

const FourElement = withServiceIcon(AdministratIcon, "Административные дела по КАС",
    ["Оспаривание нормативных правовых актов",
        "Оспаривание действий (бездействия) органов государственной власти, органов местного самоуправления, должностных лиц."])

const FiveElement = withServiceIcon(Administrat2Icon, "Дела о привлечении к административной ответственности",
    ["Защита лиц, привлекаемых к административной ответственности по КоАП"])

const SixElement = withServiceIcon(ArbitrazhIcon, "АРБИТРАЖНЫЕ ДЕЛА", ["Услуги адвоката по хозяйственным спорам для юридических лиц и предпринимателей"])

function PricesView() {
    return (
        <React.Fragment>
            <div className={"service-area"}>
                <div className={"service-container"}>
                    <div className={"service-row"}>
                        <div className={"service-text-1"}>
                            <div className={"service-section-title"}>
                                <span className={"service-section-title-text-1"}>ОБЛАСТИ ДЕЯТЕЛЬНОСТИ</span>
                                <h2 className={"service-section-title-text-2"}>Как Я Могу Вам Помочь</h2>
                            </div>
                        </div>
                        <FirstElement/>
                        <SecondElement/>
                        <ThirdElement/>
                        <FourElement/>
                        <FiveElement/>
                        <SixElement/>
                    </div>
                </div>
            </div>
            <AccordionView/>
        </React.Fragment>
    )
}

export default PricesView;