import React, { useState } from "react";
import "./ContactsView.css";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import emailjs from "emailjs-com";
import apiKey from "../../configs/emailkey";

function FormView() {
  const [validated, setValidated] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [desc, setDesc] = useState("");

  const handleSubmit = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(true);
      event.preventDefault();
      emailjs.sendForm(apiKey.SERVICE_ID, apiKey.TEMPLATE_ID, event.currentTarget, apiKey.USER_ID).then(
        result => {
          alert("Message Sent, We will get back to you shortly", result.text);
        },
        error => {
          alert("An error occurred, Please try again", error.text);
        }
      );
    }
  };

  return (
    <Form validated={validated} onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} md="12" controlId="validationCustom01">
          <Form.Control name="from_name" required type="text" placeholder="ФИО*" value={name} onChange={e => setName(e.target.value)} />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Control name="sender_email" required type="text" placeholder="почта*" value={email} onChange={e => setEmail(e.target.value)} />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Control name="sender_phone" type="text" placeholder="телефон" value={phone} onChange={e => setPhone(e.target.value)} />
        </Form.Group>
        <Form.Group as={Col} md="12" controlId="exampleForm.ControlTextarea1">
          <Form.Control
            required
            name="message"
            as="textarea"
            rows="3"
            placeholder="Опишите Вашу проблему"
            value={desc}
            onChange={e => setDesc(e.target.value)}
          />
        </Form.Group>
      </Form.Row>
      <Button type="submit" className={"form-btn"}>
        Отправить
      </Button>
    </Form>
  );
}

export default FormView;
