import React from "react";
import "./ContactsView.css"
import FormView from "./FormView";
import PlaceImg from "../../assets/image/entrance.jpg"

function ContactsView() {
    return (
        <div className={"contacts-area"}>
            <div className={"contacts-container"}>
                <div className={"contacts-row"}>
                    <div className={"contacts-text-el"}>
                        <div className={"contacts-info"}>
                            <h3 className={"contacts-big-text"}> КОНТАКТЫ </h3>
                            <h4 className={"contacts-address"}>Адресс</h4>
                            <span
                                className={"contact-address-text"}>Астраханская обл, г. Знаменск, Ленина 22</span>
                            <h4 className={"contacts-address"}>Телефон</h4>
                            <span className={"contact-address-text"}>+7 (906)-178-49-99</span>
                            <span className={"contact-address-text"}>+7 (927)-568-27-81</span>
                            <h4 className={"contacts-address"}>Почта</h4>
                            <span className={"contact-address-text"}>Ak114_mrka@mail.ru</span>
                        </div>
                    </div>
                    <div className={"contacts-form-el"}>
                        <div className={"contacts-info"}>
                            <h3 className={"contacts-big-text"}>Форма Обратной Связи</h3>
                            <FormView/>
                        </div>
                    </div>
                    <div className={"ya-map-container"}>
                        <div className={"ya-map-main"}><a
                            href="https://yandex.ru/maps/org/yuridicheskiye_uslugi/83388127153/?utm_medium=mapframe&utm_source=maps"
                            className={"ya-map-link-1"}>Юридические услуги</a><a
                            href="https://yandex.ru/maps/10947/znamensk/category/legal_services/?utm_medium=mapframe&utm_source=maps"
                            className={"ya-map-link-2"}>Юридические услуги в Знаменске</a>
                            <iframe title={"ya-frame"} src="https://yandex.ru/map-widget/v1/-/CKqTJEMG" width="560" height="400"
                                    frameBorder="1" allowFullScreen={true} className={"ya-frame"}/>
                        </div>
                        <div className={"image-area"}>
                            <img src={PlaceImg}  alt={""} className={"ya-img"}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactsView;