import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import "./PriceView.css";
import GridView from "./GridView";
import { useAccordionToggle } from "react-bootstrap";
import prices from "./prices.json";

function AccordionView() {
  const [iconKey, setIconKey] = useState(0);
  return (
    <div className={"accordion-container"}>
      <Accordion defaultActiveKey="0" className={"accordion-row"}>
        <Card>
          <Card.Header className={"accordion-card-header"}>
            <div className={"accordion-text"}>Стоимость услуг</div>
            <CustomToggle eventKey="0" iconKey={iconKey} setIconKey={setIconKey} />
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <GridView services={prices} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

function CustomToggle({ eventKey, iconKey, setIconKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {});
  return (
    <button
      className={"accordion-btn"}
      type="button"
      onClick={() => {
        iconKey===0 && setIconKey(1);
        iconKey===1 && setIconKey(0);
        decoratedOnClick();
      }}>
      {iconKey === 0 && <i className="fas fa-minus"></i>}
      {iconKey === 1 && <i className="fas fa-plus"></i>}
    </button>
  );
}

export default AccordionView;
