import React from "react";
import "./Footer.css"

function Footer() {
    return (
        <footer className={"footerArea"}>
            <div className={"footerBottomArea"}>
                <div className={"footerBottomAreaContainer"}>
                    <div className={"footerBottomContent"}>
                        <div className={"footerBottomRow"}>
                            <div className={"footerBottomRow-one"}>
                                <span className={"footerBottomRow-one-text"}>Политика конфиденциальности | © 2020 Адвокат Аврамук Анжела Валерьевна. Все права защищены</span>
                            </div>
                            <div className={"footerBottomRow-one"}>
                                <span className={"footerBottomRow-one-text"}>|</span>
                            </div>
                            <div className={"footerBottomRow-one"}>
                                <span className={"footerBottomRow-one-text"}>Ak114_mrka@mail.ru</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;