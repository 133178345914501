import React from 'react';
import TopMenu from "./TopMenu/TopMenu";
import Header from "./Header/Header";
import {BrowserRouter} from "react-router-dom";
import Routes from "./Routes";
import Footer from "./Footer/Footer";

function App() {
    return (
        <React.Fragment>
            <BrowserRouter>
                <Header/>
                <TopMenu/>
                <Routes/>
                <Footer/>
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;
