import React from "react";
import "./InfoIcon.css"

function withInfoIcon(WrappedIcon, smallText, bigText) {
    return () => {
        const style = {
            fontSize: "60px",
            lineHeight: "1.65"
        }
        return (
            <div className="infoIcon-el">
                <div className={"infoIcon-serviceItem"}>
                    <div className={"infoIcon-serviceIcon"}>
                        <WrappedIcon ownStyle={style}/>
                    </div>
                    <div className={"infoIcon-serviceText"}>
                        <span className={"small-text-info"}>{smallText}</span>
                        <h3 className={"big-text-info"}>{bigText}</h3>
                    </div>
                </div>
            </div>
        )
    }
}

export default withInfoIcon;