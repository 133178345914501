import React from "react";
import {Grid, GridColumn as Column} from "@progress/kendo-react-grid";
import "./PriceView.css"

export function GridView({services}) {
    return (
        <Grid className={"grid-main"}
            data={[...services]}
        >
            <Column field="ID" title="#" width={67}/>
            <Column field="ServiceName" right={150} title="Наименование, вид юрпомощи" headerClassName={"service-name-col"} width={972} />
            <Column field="Price" title="Стоимость, руб." headerClassName={"service-name-col"} width={250} className={"price-column"}/>
        </Grid>
    )
}

export default GridView;