import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./TopMenu.css";
import logo from "../../assets/image/logo.png";
import paths from "../../paths";
import {LinkContainer} from "react-router-bootstrap";

function TopMenu() {
    return (
        <div className="topdiv">
            <div className="nav-container">
                <Navbar bg="dark" variant="dark" expand="lg" className="navbar">
                    <Navbar.Brand className={"navbar-brand"}>
                        <img src={logo} alt="Snow" className={"navbar-img"}/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav" className={"navbar-collapse"}>
                        <Nav className="navbar-list">
                            <LinkContainer exact to={paths.index}>
                                <Nav.Link className="el">Главная</Nav.Link>
                            </LinkContainer>
                            <LinkContainer exact to={paths.about}>
                                <Nav.Link className="el">Обо мне</Nav.Link>
                            </LinkContainer>
                            <LinkContainer exact to={paths.prices}>
                                <Nav.Link className="el">Услуги</Nav.Link>
                            </LinkContainer>
                            <LinkContainer exact to={paths.contacts}>
                                <Nav.Link className="el">Контакты</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </div>
    )
}

export default TopMenu;