import React from "react";
import "./MainPage.css";

function Index() {
    return (
        <div className="main-page-container">
            <div className="custom-text custom-text1">Адвокат</div>
            <div className="custom-text custom-text2">Аврамук Анжела Валерьевна</div>
            <div className="custom-text custom-text3">
                <p className={"p-text"}>«Адвокат является независимым профессиональным советником по правовым вопросам.»</p>
            </div>
            <div className="custom-text4"><p className={"sig"}>Статья 2 Федерального закона от 31.05.2002 N 63-ФЗ "Об адвокатской деятельности и адвокатуре в Российской Федерации"</p></div>
        </div>
    )
}

export default Index;